





















































































































































.v-distpicker ::v-deep select {
  height: 35px !important;

  padding-top: 0.4rem;
  font-size: 14px;
}
.el-icon-s-fold,
.el-icon-s-unfold {
  font-size: 35px;
  margin: 22px;
  color: #999;
}
